.vendor-stat{
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 3px var(--ui-shadow);
    cursor: pointer;
}

.vendor-stat .title{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.vendor-stat .title .label{
    font-weight: bold;
    font-size: var(--font-m);
    margin-bottom: 10px;
}

.vendor-stat .title .date{
    margin-left: auto;
    font-size: var(--font-xs);
    color: var(--ui-placeholder)
}

.vendor-stat .stats{
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.vendor-stat .stats .stat *{
    user-select: none;
}


.vendor-stat .stats .stat .label{
    font-size: var(--font-xs);
    text-transform: uppercase;
    font-weight: bold;
    color: var(--ui-placeholder);
}

.vendor-stat .value{
    font-weight: bold;
    font-size: var(--font-large);
    margin: 5px 0 0;
}

.vendor-stat .value-change{
    font-size: var(--font-xs);
    display: flex;
    align-items: center;
    gap: 5px
}

.vendor-stat .value-change .positive{
    color: var(--valid-color)
}

.vendor-stat .value-change .neutral{
    color: var(--warning-color)
}

.vendor-stat .value-change .negative{
    color: var(--invalid-color)
}

@media only screen and (max-width: 900px) {

    .vendor-stat .stats{
        justify-content: flex-start;
    }

}