#help-center{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#help-center .help-topic{
  display: flex;
  align-items: center;
  background: var(--ui-element);
  padding: 40px;
  border-radius: 5px;
  cursor: pointer
}

#help-center .help-topic .icon{
  font-size: 60px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
}

#help-center .help-topic .info{
  display: flex;
  font-size: var(--font-xl);
  flex-direction: column;
}

#help-center .help-topic .info .help{
  font-size: 18px;
  color: var(--ui-placeholder);
  margin-top: 10px;
  font-weight: normal;
}
