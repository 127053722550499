#add-product-addon-modal .section{
  margin: 20px 0;
}

#add-product-addon-modal .section:last-child{
  margin-bottom: 0
}

#add-product-addon-modal .section > .title h3{
  font-weight: 900;
  margin-bottom: 10px;
  font-size: var(--font-m)
}

#add-product-addon-option-modal #add-new-addon-button,
#add-product-addon-modal #add-new-addon-button{
  display: flex;
  justify-content: center;
  margin: 10px 0;
  cursor: pointer;
}

#add-product-addon-option-modal #add-new-addon-button .fa,
#add-product-addon-modal #add-new-addon-button .fa{
  margin-right: 5px;
}

#add-product-addon-modal .progress-steps{
  margin: 40px 60px;
}
