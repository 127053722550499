.image-uploader{
  display: flex;
  align-items: center;
  background: var(--ui-element);
  border-radius: 5px;
  overflow: hidden;
}

.image-uploader .preview{
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
  position: relative;
  display: flex;
  align-items: center;
}

.image-uploader .preview .delete{
  display: none;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  justify-content: center;
  color: var(--main-color);
  font-size: var(--font-m);
  cursor: pointer;
}

.image-uploader .preview:hover .delete{
  display: flex;
}

.image-uploader .file-uploader{
  margin-left: auto;
  flex: 1
}

.image-uploader .file-uploader .drop-box{
  width: 100%;
}

.image-uploader .file-uploader .drop-box > span{
  white-space: nowrap;
}

.image-uploader .preview img{
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 480px) {

  .image-uploader .preview{
    height: 60px;
  }

  .image-uploader .file-uploader .drop-box{
    padding: 30px;
  }

}
