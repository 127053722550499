#product-card-preview .card {
  width: 100%;
  border: 1px solid var(--ui-border);
  box-sizing: border-box;
  margin: 0;
  padding: 15px;
  font-size: var(--font-s);
  font-weight: bold;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  position: relative;
}

#product-card-preview > .title{
  color: var(--main-color);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

#product-card-preview  .availability{
  color: var(--invalid-color);
  font-size: var(--font-xs);
  display: flex;
  margin-top: 5px;
  align-items: center;
}

#product-card-preview  .availability > .fa{
  margin-right: 5px;
}

#product-card-preview  .product-cover{
  height: 200px;
  background-size: cover;
  background-position: center;
  margin: -15px -15px 15px;
  position: relative;
}

#product-card-preview  .product-cover .product-tags{
  right: 10px;
  bottom: 10px;
  position: absolute;
}

#product-card-preview  .product-cover .tag{
  background: var(--main-bg);
  padding: 5px;
  margin: 0 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid var(--ui-border)
}

#product-card-preview  .product-thumb{
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  margin-right: 15px;
  background-repeat: no-repeat;
}

#product-card-preview  .menu-item-info{
  flex-direction: column;
  display: flex;
  flex: 1;
}

#product-card-preview  .menu-item-info .menu-item-title{
  display: flex;
  margin-bottom: 5px;
}

#product-card-preview  .menu-item-info .menu-item-title .title{
  margin-right: 20px;
}

#product-card-preview  .price-box{
  display: flex;
  margin-left: auto;
}

#product-card-preview .free-delivery .price-box{
  margin-right: 45px;
}

#product-card-preview  .price{
  margin-left: auto;
  white-space: nowrap;
}

#product-card-preview .on-sale .price{
  color: var(--main-color);
  margin-left: 0;
}

#product-card-preview  .discounted-price{
  text-decoration: line-through;
  margin-right: 10px;
  margin-left: auto;
}

#product-card-preview  .desc{
  color: var(--ui-placeholder);
  font-size: var(--font-s);
  line-height: 1.2
}

#product-card-preview .tags{
  margin-top: auto;
}

#product-card-preview .tags .product-tags{
  justify-content: flex-end
}
