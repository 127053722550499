.badge{
    padding: 8px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: var(--font-xxs);
    text-transform: uppercase;
    align-items: center;
    background: var(--ui-bg);
    border-radius: 5px;
    gap: 5px;
    color: var(--ui-text);
    border: 1px solid var(--ui-border);
    background: var(--ui-element);
}

.badge .fa{
    align-items: center;
    display: flex;
    color: var(--ui-placeholder);
}

.badge .amount{
    display: flex;
    align-items: center;
    gap: 5px;
}

.badge.main{
    color: var(--main-bg);
    border: 1px solid var(--main-color);
    background: var(--main-color);
}

.badge.main .fa{
    color: var(--main-bg);
}

.badge.valid{
    color: white;
    border: 1px solid var(--valid-color);
    background: var(--valid-color);
}

.badge.valid .fa{
    color: white;
}

.badge.warning{
    color: white;
    border: 1px solid var(--warning-color);
    background: var(--warning-color);
}

.badge.warning .amount,
.badge.warning .fa{
    color: white;
}

.badge.invalid{
    color: white;
    border: 1px solid var(--invalid-color);
    background: var(--invalid-color);
}

.badge.invalid .fa{
    color: white;
}

.badge.grey{
    color: white;
    border: 1px solid grey;
    background: grey;
}

.badge.grey .fa{
    color: white;
}

.badge.orange{
    color: white;
    border: 1px solid #ff8b12;
    background: #ff8b12;
}

.badge.orange .fa{
    color: white;
}

.badge.blue{
    color: white;
    border: 1px solid #1984ff;
    background: #1984ff;
}

.badge.blue .fa{
    color: white;
}

.badge.lightgreen{
    color: white;
    border: 1px solid #32d13b;
    background: #32d13b;
}

.badge.lightgreen .fa{
    color: white;
}

.badge.darkgreen{
    color: white;
    border: 1px solid #3c5922;
    background: #3c5922;
}

.badge.darkgreen .fa{
    color: white;
}
