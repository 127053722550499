.connection-status{
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  color: var(--main-title);
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;  
}

.connection-status.connected{
  background-color: var(--valid-color);
}

.connection-status.disconnected{
  background-color: var(--invalid-color);
}


@media only screen and (max-width: 800px) {

  .connection-status{
    width: 40px;
    height: 40px;
    box-sizing: border-box
;
  }
  
  .connection-status .placeholder{
    display: none;
  }

  .connection-status .placeholder{
    display: none;
  }

}