:root{
  --font-xxs: 12px;
  --font-xs: 14px;
  --font-s: 16px;
  --font-m: 20px;
  --font-l: 24px;
  --font-xl: 28px;
  --font-xxl: 32px;
  --font-large: 2.5em
}

@media only screen and (max-width: 600px) {

  :root{
    --font-xxs: 10px;
    --font-xs: 12px;
    --font-s: 14px;
    --font-m: 18px;
    --font-l: 22px;
    --font-xl: 26px;
    --font-xxl: 30px;
    --font-large: 2em
  }

}
