#colors-list{
    display: flex;
    align-items: center;
    gap: 20px;
}

#colors-list .color{
    display: flex;
    align-items: center;
    cursor: pointer;
}

#colors-list .color .color-box{
    width: 60px;
    height: 60px;
    background-color: var(--main-color);
    border-radius: 5px;
    margin-right: 10px;
}

#colors-list .color.secondary .color-box{
    background-color: var(--secondary-color);
}

#colors-list .color .info{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#colors-list .color .info span:first-child{
    font-size: var(--font-l);
    font-weight: bold;
}

#colors-list .color .info span:last-child{
    color: var(--ui-placeholder);
}