.purchase-details{
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.purchase-details .detail{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.purchase-details .detail > .inner{
    display: flex;
    align-items: center;
    gap: 5px
}

.purchase-details .detail .icon{
    color: var(--main-color)
}

.purchase-details .detail .label{
    font-size: var(--font-xs);
    color: var(--ui-placeholder)
}

.purchase-details .detail:last-child{
    margin-left: auto;
}

@media only screen and (max-width: 800px) {

    .purchase-details{
        justify-content: center;
    }

    .purchase-details .detail:last-child{
        margin-left: 0;
    }

}