.materials-list{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
  
.materials-list .material{
    background-color: var(--ui-bg);
    padding: 20px;
    border-radius: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 380px;
}

.materials-list .material.main .image{
    background: var(--main-color);
    padding: 40px;
    border-radius: 5px;
}

.materials-list .material.secondary .image{
    background: var(--secondary-color);
    padding: 40px;
    border-radius: 5px;
}

.materials-list .material .title{
    margin-bottom: 20px;
    margin-top: auto;
    text-align: center;
}

.materials-list .material .image{
    flex: 1;
    align-items: center;
    display: flex;  
    margin-bottom: 20px;
}

.materials-list .material .note{
    margin-bottom: 20px;
}

.materials-list .material img{
    width: 100%;
    max-width: 260px;
}