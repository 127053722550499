.purchase-set-preparation-time .current-time{
  font-size: var(--font-m)
}

.purchase-set-preparation-time .current-time .fa{
  margin-left: 5px;
  font-size: var(--font-s)
}

.purchase-set-preparation-time .ready-for-collect{
  display: flex;
  align-items: center;
  justify-content: center;
}

.purchase-set-preparation-time .ready-for-collect .label{
  display: flex;
  flex-direction: column;
}

.purchase-set-preparation-time .ready-for-collect .label .text{
  color: var(--invalid-color);
  font-size: var(--font-xxs);
  margin-bottom: 5px;
}
