.responsive-table {
  margin: 0;
  padding: 0;
  width: 100%;
  border-spacing: 0 5px;
  border-collapse: separate;
}

.table-scroller {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}

.table-scroller::-webkit-scrollbar {
  display: none;
}

.table-scroller{
  overflow-x: scroll;
  overflow-y: hidden;
}  

.responsive-table tr {
  border: 0;
  padding: 0;
  border-radius: 5px;
}

.responsive-table th,
.responsive-table td {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 0 solid var(--ui-border);
  vertical-align: middle;
}

.responsive-table tr th:first-child,
.responsive-table tr td:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.responsive-table tr th:last-child,
.responsive-table tr td:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.responsive-table th {
  font-size: var(--font-s);
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--ui-bg-helper);
}

.responsive-table td {
  font-size: var(--font-s);
  box-sizing: border-box;
  background-color: var(--ui-bg);
  vertical-align: middle;
  white-space: nowrap;
}

.responsive-table th.text-left,
.responsive-table td.text-left{
  text-align: left;
}

.responsive-table th.text-right,
.responsive-table td.text-right{
  text-align: right;
}

.responsive-table td.center .cell,
.responsive-table td .cell.centered{
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  
  .table-scroller{
    overflow-x: scroll;
    overflow-y: visible;
  }  

  .responsive-table.responsive {
    border: 0;
  }

  .responsive-table.responsive caption {
    font-size: 1.3em;
  }

  .responsive-table.responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .responsive-table.responsive tr {
    display: block;
    margin-bottom: 20px;
  }

  .responsive-table.responsive tr:nth-child(even) th,
  .responsive-table.responsive tr:nth-child(even) td{
    background-color: var(--ui-bg-helper);
  }

  .responsive-table.responsive td {
    width: 100%;
    border-bottom: 1px solid var(--ui-border);
    border: 0;
    border-bottom: 1px solid var(--ui-border);
    display: flex;
    align-items: center;
    font-size: var(--font-s);
    text-align: right;
  }

  .responsive-table.responsive td .cell{
    margin-left: auto;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
  }

  .responsive-table.responsive td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .responsive-table.responsive
    content: attr(aria-label);
    */
    content: attr(data-label);
    display: flex;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
  }

  .responsive-table.responsive td:last-child {
    border-bottom: 0;
  }
}
