/********** Page title **********/

.page-title{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/********** Page title end **********/

/********** Table **********/


.table{
  width: 100%;
}

.table .link{
  cursor: pointer;
}

.sortable-helper-row{
  display: table-column;
  width: 100%;
  flex: 1
}

.sortable-helper-row > *{
  display: table-cell;
  width: auto;
}

.sortable-helper-row td,
.table th,
.table td{
  padding: 10px;
  text-align: left;
  border-top: 1px solid var(--ui-border);
  border-bottom: 1px solid var(--ui-border);
  box-sizing: border-box;
  vertical-align: middle;
  background: var(--ui-bg);
  position: relative;
}

.table th{
  font-weight: bold;
  background: var(--ui-bg-helper)
}

.sortable-helper-row td:first-child,
.table th:first-child,
.table td:first-child{
  border-left: 1px solid var(--ui-border);
}

.table th:last-child,
.sortable-helper-row td:last-child,
.table td:last-child{
  border-right: 1px solid var(--ui-border);
}

.sortable-helper-row td .field-filter,
.table td .field-filter{
  display: none;
  color: var(--main-color);
  font-size: var(--font-xs);
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.sortable-helper-row td:hover .field-filter,
.table td:hover .field-filter{
  display: block;
}

.sortable-helper-row .spacer,
.table .spacer{
  width: 80%;
}

.sortable-helper-row .center,
.table .center{
  text-align: center;
  align-items: center;
}

.sortable-helper-row .status,
.table .status{
  align-items: center;
  width: 15px;
  line-height: 1;
  text-align: center;
  font-size: var(--font-xxs);
}

.sortable-helper-row .status .fa,
.table .status .fa{
  color: var(--valid-color)
}

.sortable-helper-row.disabled .status .fa,
.table .disabled .status .fa{
  color: var(--invalid-color)
}

.table .actions > div{
  display: flex;
  justify-content: flex-end;
}

.table .actions .button{
  white-space: nowrap;
}

.table .actions .fa{
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.table .actions .button:hover .fa{
  color: var(--main-color)
}

/********** Table end **********/


/********** Info table start **********/

.info-table{
  display: flex;
  flex-direction: column;
}

.info-table .info-table-item{
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid var(--ui-border);
}

.info-table .info-table-item .label{
    display: flex;
    align-items: center;
    color: var(--ui-placeholder);
    font-size: var(--font-s);
    line-height: 1;
}

.info-table .info-table-item .label .fa{
  color: var(--main-color);
  margin-right: 10px;
}

.info-table .info-table-item .info{
  margin-left: auto;
  text-align: right;
}

@media only screen and (max-width: 480px) {

  .info-table .info-table-item{
    flex-direction: column;
  }

  .info-table .info-table-item .info{
    margin-left: 0;
    text-align: left;
    margin-top: 5px;
  }

}

/********** Info  table end **********/

/********** Tabs view start ***********/

.tabs-view{
  display: flex;
}

.tabs-view .menu{
  width: 260px;
  border-right: 1px solid var(--ui-border);
}

.tabs-view .tabs{
  border: 1px solid var(--ui-border);
  border-left: 0;
  flex: 1;
}

.tabs-view .menu .vertical-tabs-menu{
  width: 100%;
}

@media only screen and (max-width: 480px) {

  .tabs-view{
    flex-direction: column;
  }

  .tabs-view .menu{
    width: 100%;
  }

}

/********** Tabs view end ***********/
