#purchase-promotions{
  margin-top: 15px;
  display: flex;
  align-items: center;
}

#purchase-promotions .items{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: auto;
  margin-left: auto;
  justify-content: flex-end;
}

#purchase-promotions .promotions-title{
  font-size: var(--font-s);
  display: flex;
  background: var(--valid-color);
  border-radius: 5px;
  color: white;
  padding: 10px;
  align-items: center;
  cursor: pointer;
}

#purchase-promotions .promotions-title .title h4{
  font-size: var(--font-s);
  font-weight: normal;
}
  
#purchase-promotions .promotions-title i{
  font-size: var(--font-m);
}
  
#purchase-promotions .promotions-title .info{
  margin-left: 5px;
}

#purchase-promotions .promotions-title .info span{
  font-size: var(--font-s)

}