#authentication-error-wrapper{
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 75vh
}

#authentication-error-wrapper span.fa{
  font-size: 80px;
  color: var(--main-color);
}

#authentication-error-wrapper p.error-text{
  color: var(--ui-text);
  margin: 20px 0;
}
