.modal{
  display: flex;
  flex-direction: row;
  height: 100%;
  max-height: 80vh;
  border-radius: 5px;
  z-index: 9000;
  box-shadow: 0px 8px 8px 0px var(--ui-shadow);  
  background: var(--ui-bg)
}

.modal .modal-info{
  width: 90vw;
  max-width: 720px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.modal.full .modal-info{
  max-width: none;
}

.modal .modal-header{
  display: flex;
  flex-direction: column;
}

.modal .modal-header .title{
  font-size: var(--font-l);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.modal .modal-content{
  margin-bottom: 5px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal .modal-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal .modal-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.modal .modal-footer{
  margin-top: auto;
  display: flex;
  min-height: 40px;
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {

  .modal{
    flex-direction: column;
  }

  .modal .modal-info{
    width: 90vw
  }
  
  .ReactModal__Body--open .actions{
    flex-direction: column;
  }

}

.ReactModal__Overlay{
  z-index: 999;
  background-color: var(--ui-shadow) !important;
}

.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
  height: 100%;
}