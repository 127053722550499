#vendor-welcome{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#vendor-welcome .icon{
    width: 180px;
    height: auto;
}

#vendor-welcome .icon img{
    width: 100%;
    height: auto;
}

#vendor-welcome .text{
    text-align: center;
}