.image-cropper{
    position: relative;
}

.crop-container{
    position: relative;
    height: 30vh;
}

.crop-container .reactEasyCrop_CropArea{
    color: var(--ui-overlay);
}

.crop-modal .controls > div{
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: var(--ui-element);
    border-radius: 5px;
    margin: 10px 0;
}