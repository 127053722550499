#vendor-terms .terms{
  display: flex;
  flex-direction: column;
  gap: 15px
}

#vendor-terms .term .placeholder{
  display: flex;
  flex-direction: column;
}

#vendor-terms .term .placeholder .help{
  line-height: 1.2;
}

#vendor-terms .term .placeholder > span:first-child{
  font-size: var(--font-m);
  color: var(--main-text);
  margin-bottom: 2px;
}

#vendor-terms .note{
  margin: 10px 0;
  line-height: 1.2;
}

#vendor-terms .punishment{
  margin: 20px 0 20px;
  display: flex;
  align-items: center;
  line-height: 1.2;
}

#vendor-terms .punishment .fa{
  color: var(--warning-color);
  font-size: var(--font-xxl);
  margin-right: 20px;
}
