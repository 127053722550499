#product-details .price-box,
#product-details .purchase-qty{
  display: flex;
}

#product-details .price-box > *,
#product-details .purchase-qty > *{
  flex: 1;
  margin-left: 10px;
}

#product-details .price-box > *:first-child,
#product-details .purchase-qty > *:first-child{
  margin-left: 0
}

#product-details .help{
  font-size: var(--font-xs);
  color: var(--ui-placeholder);
  margin-top: 10px;
  display: flex;  
}