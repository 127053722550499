#purchase-instructions .totals-info{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
  
#purchase-instructions .totals-info .icon{
    color: var(--main-color);
    width: 30px;
    text-align: center;
    margin-right: 10px;
}
  
#purchase-instructions .totals-info .info{
    flex: 1;
    line-height: 1.2;
    cursor: pointer;
}