.set-time{
  display: flex;
  border-radius: 5px;
  overflow: hidden
}

.set-time > *{
  border: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 15px;
  background: var(--ui-element);
  outline: 0;
}

.set-time input{
  color: var(--main-text);
  font-size: var(--font-m);
  width: 60px;
  box-sizing: border-box;
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.set-time button{
  cursor: pointer;
  font-size: var(--font-m);
}

.set-time .cancel{
  color: var(--invalid-color);
  border-right: 1px solid var(--ui-border);
}

.set-time .accept{
  color: var(--valid-color);
  border-left: 1px solid var(--ui-border);
}
