.switch .onoffswitch {
  position: relative;
  width: 55px;
  display: inline-block;
  font-size: 80%;
}

.switch .onoffswitch .onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid var(--ui-border);
  border-radius: 20px;
  margin: 0;
}

.switch .onoffswitch .onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  transition: margin 0.15s ease-in-out;
}
  
.switch .onoffswitch .onoffswitch-inner:before,
.switch .onoffswitch .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 24px;
  padding: 0;
  line-height: 24px;
  font-size: 80%;
  color: hsl(0, 0%, 100%);
  font-weight: normal;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.switch .onoffswitch .onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: var(--valid-color);
  color: hsl(0, 0%, 100%);
}
  
.switch .onoffswitch .onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: var(--ui-element-hvr);
  color: var(--ui-placeholder);
/*
  background-color: var(--invalid-color);
  color: hsl(0, 0%, 100%);
*/  
  text-align: right;
}

.switch .onoffswitch .onoffswitch-switch {
  width: 22px;
  height: 22px;
  margin: 0;
  background: var(--ui-border);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  bottom: 0;
  transition: right 0.15s ease-in-out;
}
  
.switch {
  position: relative;
  display: inline-flex;
  margin-left: 5px;
  align-items: center;
  text-align: left;
  overflow: hidden;
}

.switch input[type=checkbox] {
  position: absolute;
  left: 10px;
  visibility: hidden;
}

.switch input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.switch input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
  right: 1px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.switch input[type=checkbox]:focus ~ .onoffswitch {
  outline: thin dotted #333;
  outline: 0;
}

.switch label {
  position: absolute;
  cursor: pointer;
  padding-left: 65px;
  display: inline-block;
  text-align: left;
  line-height: 24px;
  width: 100%;
  z-index: 1;
  height: 24px;
  font-weight: 200;
}

.switch .aural {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.switch .aural:focus {
  clip: rect(0, 0, 0, 0);
  font-size: 1em;
  height: auto;
  outline: thin dotted;
  position: static !important;
  width: auto;
  overflow: visible;
}
  
.switch .toggle-string {
  line-height: 1;
  flex: 1;
  flex-direction: column;
  display: flex;
  font-size: var(--font-s);
}

.switch .toggle-string .help{
  color: var(--ui-placeholder);
  margin-top: 5px;
  font-size: var(--font-xs);
}