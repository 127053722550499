.priority-bar {
  width: 100%;
  white-space: nowrap;
  position: relative;
  border-radius: 5px;
  box-sizing: border-box;
}

.priority-bar .priority-bar-items {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  display: flex;
}

.priority-bar .priority-bar-items .toggle{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.priority-bar .priority-bar-items > *{
  display: flex;
  flex-wrap: nowrap;
  margin-right: 10px;
}

.priority-bar .priority-bar-items > *:last-child{
  margin-right: 0;
}

.priority-bar .priority-bar-items > *.left + *{
  margin-left: auto;
}

.priority-bar .more-items-box {
  display: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 26px;
  list-style: none;
  border-top: 1px solid var(--main-border);
}

.priority-bar .more-items-box.opened {
  display: flex;
  flex-wrap: wrap;
}

.priority-bar .more-items-box > *{
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 480px) {

  .priority-bar .more-items-box{
    flex-direction: column;
  }

  .priority-bar .more-items-box > *{
    width: 100%;
  }

}
