.text-content a{
  text-decoration: underline;
}

.text-content span{
  margin: 10px 0;
  display: inline-block;
  color: var(--ui-placeholder)
}

.text-content h3{
  font-size: var(--font-l);
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.4;
}

.text-content h4{
  font-size: var(--font-l);
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.4;
}

.text-content h5{
  font-size: var(--font-m);
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.4;
}

.text-content p{
  margin: 1rem 0;
  display: block;
  font-size: var(--font-m);
  line-height: 1.2;
  margin-bottom: 1rem;

}

.text-content ol{
  list-style: decimal;
  padding-left: 4rem;
}

.text-content ul{
  list-style: none;
  padding-left: 40px;
  margin: 1rem 0;
  color: var(--main-color)
}

.text-content ul.list{
  list-style: disc
}

.text-content ol li,
.text-content ul li{
  padding: 5px 0;
  color: var(--ui-text);
  font-size: var(--font-m);
  line-height: 1.2;
}

.text-content strong,
.text-content b{
  font-weight: bold;
}


@media only screen and (max-width: 960px) {
  
  .text-content ol{
    padding-left: 3rem;
  }

}