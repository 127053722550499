#purchase-status{
    display: flex;   
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

#purchase-status .actions{
    margin-left: auto;
}

#purchase-status .info{
    font-size: var(--font-m);
    display: flex;
    flex-direction: column;
    gap: 5px
}

#purchase-status .info span:last-child{
    color: var(--ui-placeholder);
    font-size: var(--font-s);
}

@media only screen and (max-width: 800px) {

    #purchase-status .actions{
        margin-left: 0;
    }

    #purchase-status{
        justify-content: center;
    }
}