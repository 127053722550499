.labeled-date{
  position: relative;
}

.labeled-date input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0;
  padding: 15px;
  background: var(--ui-bg);
  color: var(--ui-text);
  border: 1px solid var(--ui-border);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: none;
}

.labeled-date.populated input {
  padding-top: 28px;
  padding-bottom: 12px;
}

.labeled-date input + span {
  color: var(--main-color);
  font-size: 12px;
  position: absolute;
  top: 0px;
  left: 20px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.labeled-date.populated input + span {
  opacity: 1;
  top: 10px;
  left: 15px;
}

.labeled-date input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button{
    display: none;
    -webkit-appearance: none;
}

input[type="date"] {
  position: relative;
  padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: '\f073';

  position: absolute;
  top: 50%;
  transform: translateY(-30%);
  cursor: pointer;
  right: 10px;
  color: var(--ui-placeholder)
}
