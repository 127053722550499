.phone-picker{
  border: 1px solid var(--ui-border);
  background: var(--ui-element);
  display: flex;
  position: relative;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
}

.phone-picker .labeled-input input{
  box-shadow: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.phone-picker .country{
  border-right: 1px solid var(--ui-border);
  cursor: pointer;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-picker .country .country-code{
  margin: 0 5px;
}

.phone-picker .country-dropdown{
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  background: var(--ui-element);
  z-index: 999;
  left: -1px;
  width: 100%;
  bottom: -5px;
  overflow-x: scroll;
  transform: translateY(100%);
  max-height: 0;
  box-shadow: 0px 8px 8px 0px var(--ui-shadow);
  transition: all 200ms ease-in;
  border-radius: 5px;
}

.phone-picker .country-list {
  overflow-x: scroll;
  border-top: 0;
}

.phone-picker .country-list input{
  width: 100%;
  background: var(--ui-element);
  box-sizing: border-box;
  padding: 15px;
  border: 0;
  border-bottom: 1px solid var(--ui-border);
  font-size: var(--font-s)
}

.phone-picker .country-dropdown.opened{
  max-height: 300px;
}

.phone-picker .country-list li{
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid var(--ui-border)
}

.phone-picker .country-list li:last-child{
  border-bottom: 0;
}

.phone-picker .country-list li.divider{
  padding: 0;
  border-bottom: 1px solid var(--ui-border)
}

.phone-picker .country-list li span{
  margin: 0 5px;
}

.phone-picker .labeled-input{
  flex: 1;
  margin: 0 !important;
}

.phone-picker .labeled-input input{
  background-color: transparent;
  width: 100%;
  border: 0;
}
