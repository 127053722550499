.active-package-placeholder,
#offers .heading h2{
  display: flex;
  gap: 20px;
}

.active-package-placeholder,
#offers .heading .active-package{
  color: var(--valid-color);
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;
}

.active-package-placeholder span,
#offers .heading .active-package span{
  font-size: var(--font-m);
}


#offers .offer-footer{
  border-top: 1px solid var(--ui-border);
  margin-top: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
}

#offers .offer-footer .logo{
  height: 20px;
  overflow: hidden;
}

#offers .offer-footer .icons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

#offers .icons .icon span{
  margin-right: 5px;
  color: var(--main-color)
}

#offers .offer-title{
  margin-left: auto;
}

#offers .offer-block{
  margin: 20px 0 40px;
}

#offers .offer-icon-list{
  display: flex;
  margin-bottom: 20px;
}

#offers .offer-icon-list ul{
  list-style: circle;
  padding-left: 40px;
}

#offers .offer-icon-list .icon{
  color: var(--main-color);
  font-size: 48px;
  margin-right: 20px;
  width: 60px;
  text-align: center;
}

#offers .offer-icon-list .text{
  display: flex;
  flex-direction: column;
  flex: 1
}

#offers .offer-icon-list .price{
  flex-direction: column;
  display: flex;
  margin-left: auto;
  margin-left: 10px;
  text-align: right;
  justify-content: flex-end;
}

#offers .offer-icon-list .price span:first-child{
  color: var(--main-color);
  font-size: var(--font-l);
}

#offers .offer-icon-list .text .t1{
  font-size: var(--font-m);
  font-weight: bold;
}

#offers .offer-icon-list .text .t2{
  color: var(--ui-placeholder);
  margin-top: 5px;
}

#offers .warning{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--font-s);
}

#offers .warning a{
  text-decoration: underline;
}

#offers .warning .fa::before{
  color: var(--warning-color);
  vertical-align: super;
}

#offers .offer-icon-list .requirements{
  margin-top: 15px;
}

#offers .offer-icon-list .requirements ul li{
  padding: 5px 0;
}

#offers .offer-icon-list b{
  font-weight: bold;
}

#offers .sign-up{
  font-size: var(--font-m);
  
}

#offers .sign-up a{
  color: var(--main-color);
}

@media only screen and (max-width: 800px) {

  #offers .offer-footer{
    display: flex;
    flex-direction: column-reverse;
  }

  #offers .offer-footer .logo{
    margin-top: 20px;
  }

  #offers .offer-footer .icons{
    margin: 0;
  }

  #offers .offer-footer .icons .icon{
    margin-right: 20px;
  }

  #offers .offer-footer .icons .icon:last-child{
    margin: 0;
  }

}

@media only screen and (max-width: 600px) {

  #offers .offer-header{
    flex-direction: column;
  }

  #offers .offer-title{
    margin: 10px 0 0;
  }

  #offers .offer-footer .icons{
    flex-direction: column;
  }

  #offers .offer-footer .icons .icon,
    #offers .offer-footer .icons .icon:last-child{
    margin: 5px 0;
  }

}
