#balance-history .filter-bar{
  margin-bottom: 20px;
}
#balance-history .price-field{
  text-align: right;
}

#balance-history .date{
  white-space: nowrap;
}

#balance-history .description-field > div{
  display: flex;
  flex-direction: column;
}

#balance-history .description-field > div .message{
  color: var(--ui-placeholder);
  font-size: var(--font-xs);
  margin-top: 3px;
}

#balance-history .item .tomato-point{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#balance-history .item img{
  width: 20px;
  margin-left: 3px;
  height: auto;
}

#balance-history .actions{
  margin-bottom: 15px;
}
