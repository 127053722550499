.actions{
  display: flex;
  gap: 15px;
}

.actions.fluid{
  flex: 1
}

.actions .button{
  flex: 1
}
