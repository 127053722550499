.unread{
    font-size: 10px;
    background: var(--main-color);
    border-radius: 0.8em;
    color: var(--main-title);
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 15px;
    text-align: center;
    width: 1.6em;
    position: absolute;
    top: 2px;
    right: -8px;
  }