.stripe-connected{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: var(--font-l);
}

.stripe-connected .fa{
  color: var(--valid-color);
  margin-right: 5px; 
}
