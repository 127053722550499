.changelogs{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.changelogs .changelog{
    background-color: var(--ui-element);
    border-radius: 5px;
    padding: 15px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

}

.changelogs .changelog .desc{
    color: var(--ui-placeholder);
}

.changelogs .changelog .read-more{
    margin-left: auto;
}