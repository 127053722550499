.sidebar-menu{
  border-radius: 5px;
  overflow: hidden;
}

.sidebar-menu ul{
  display: flex;
  flex-direction: column;
}

.sidebar-menu.bordered li{
  border-bottom: 1px solid var(--ui-border)
}

.sidebar-menu.bordered li:last-child{
  border-bottom: 0;
}

.sidebar-menu li button,
.sidebar-menu li a{
  display: flex;
  width: 100%;
  font-size: var(--font-s);
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.sidebar-menu li a .text{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.sidebar-menu li button .help-text,
.sidebar-menu li a .help-text{
  margin-top: 5px;
  font-size: var(--font-xs);
  color: var(--ui-placeholder);
}


.sidebar-menu li a > *,
.sidebar-menu li button > *{
  padding: 15px 0;
  white-space: nowrap;
}

.sidebar-menu li a.active{
  background: var(--main-color);
  color: var(--main-title);
  font-size: var(--font-s);
}

.sidebar-menu li button .text{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sidebar-menu li button .text > *{
  line-height: 1;
}

.sidebar-menu li a.active .help-text{
  color: var(--main-title)
}

.sidebar-menu li button .fa,
.sidebar-menu li a .fa{
  padding: 0 15px;
  width: 20px;
  text-align: center;
  font-size: var(--font-m);
  color: var(--main-color);
}

.sidebar-menu li button .fa-dot-circle,
.sidebar-menu li a .fa-dot-circle{
  font-size: var(--font-xs);
  color: var(--main-placeholder)
}

.sidebar-menu ul li a.active .fa{
  color: var(--main-title);
}

.sidebar-menu ul.submenu li a.active .fa{
  color: var(--main-text);
}

.sidebar-menu li button > span{
  width: 100%;
  text-align: left;
}

.sidebar-menu li button .toggle{
  margin-left: auto;
  font-size: var(--font-s);
  transition: all linear 200ms;
  color: var(--main-placeholder);
  transform:rotate(-180deg);
  cursor: pointer;
}

.sidebar-menu li.opened button .toggle{
   transform:rotate(0);
}

.sidebar-menu li .submenu{
  height: 0;
  overflow: hidden;
  transition: all linear 200ms;
}

.sidebar-menu li .submenu li a,
.sidebar-menu li .submenu li button{
  background: var(--main-bg-helper);
}

.sidebar-menu ul ul li .fa{
  color: var(--main-placeholder);
}

.sidebar-menu li .submenu li a.active{
  color: var(--main-color);
  background: var(--main-element);
}

.sidebar-menu ul ul li a.active .help-text{
  color: var(--main-placeholder)
}
