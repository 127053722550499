.changelogs-history{
    display: flex;
    flex-direction: column;
    gap: 20px
}

.changelogs-history .changelog{
    border-bottom: 1px solid var(--ui-border);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 15px

}

.changelogs-history .changelog .title{
    padding-bottom: 10px;
    font-weight: bold;
    font-size: var(--font-m);
    display: flex;
    align-items: center;
}

.changelogs-history .changelog .button{
    margin-right: auto;
}