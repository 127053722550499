.language-switcher{
  display: flex;
  list-style: none;
}

.language-switcher .language{
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--ui-placeholder)
}

.language-switcher .language.active{
  color: var(--ui-text)
}

.language-switcher img{
  width: 20px;
  margin-right: 5px;
  height: auto;
}
