#product .previews{
  display: flex;
  justify-content: center;
  width: 100%;
}

#product .previews #product-card-preview{
  max-width: 460px;
  width: 100%;margin-bottom: 20px;
}

#new-product .content{
  display: flex;
  flex-wrap: wrap
}

#new-product .progress-steps{
  margin-bottom: 60px;
}

#new-product .content > *{
  flex: 1
}

#new-product #product-tag-editor{
  margin-bottom: 20px;
}

#new-product .product-confirm{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

#new-product .review{
  flex: 1;
  margin-right: 20px;
}

#new-product .addons-review{
  flex: 1;
  margin-left: 20px;
}

#new-product .block-title{
  font-size: var(--font-s);
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--ui-border);
  margin-bottom: 10px;
}

#new-product #product-card-preview{
  max-width: 400px;
  margin: 0 auto 40px;
}

#new-product #product-status{
  flex: 1;
}
