.copy-input{
    display: flex;
    padding: 20px;
    background-color: var(--ui-element);
    cursor: pointer;
    align-items: center;
    border-radius: 5px;
}

.copy-input :disabled{
    opacity: 1;
    cursor: pointer;
}

.copy-input .label{
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.copy-input .icon{
    margin-right: 10px;
}

.copy-input input{
    background-color: transparent;
    border: none;
    padding: 0%;
    width: 100%;
    text-align: right;
}

.copy-input .value{
    margin-right: 20px;
    flex: 1
}