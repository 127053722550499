#purchase-feedback{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

#purchase-feedback .product-feedback{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#purchase-feedback .product-feedback .faces{
  display: flex;
  margin-bottom: 10px;
  font-size: var(--font-xxl)
}

#purchase-feedback .product-feedback .faces .face{
  margin: 0 10px;
  opacity: .7;
  color: var(--ui-placeholder)
}

#purchase-feedback .product-feedback .faces .face.active{
  opacity: 1;
}

#purchase-feedback .product-feedback .faces .face:first-child{
  margin-left: 0;
}

#purchase-feedback .product-feedback .faces .face:last-child{
  margin-right: 0;
}

#purchase-feedback .product-feedback .active .fa-frown{
  color: var(--invalid-color)
}

#purchase-feedback .product-feedback .active .fa-meh{
  color: var(--warning-color)
}

#purchase-feedback .product-feedback .active .fa-smile{
  color: var(--valid-color)
}
