.button{
  position: relative;
  padding: 8px 12px;
  font-size: var(--font-s);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ui-element);
  border: 1px solid  transparent;
  color: var(--ui-text);
  cursor: pointer;
  margin: 0;
  border-radius: 5px;
  outline: none;
  transition: all .2s ease-in-out;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
}

.button:hover{
  background: var(--ui-element-hvr);
}

.button.alt{
  background: var(--ui-element);
}

.button.alt:hover{
  background: var(--ui-element-hvr);
}

.button.main{
  background: var(--main-color);
  color: #fff;
  border-color: var(--main-color)
}

.button.main:hover{
  border-color: transparent;
  background-color: var(--main-color-hvr);
}

.button.approve{
  background: var(--valid-color);
  color: #fff;
  border-color: transparent
}

.button.approve:hover{
  border-color: transparent;
  background-color: #3a903a;
}

.button.reject{
  background: var(--invalid-color);
  color: #fff;
  border-color: transparent
}

.button.reject:hover{
  border-color: transparent;
  background-color: #dd4208;
}

.button.option{
  background: #444;
  color: #fff;
  border-color: #444
}

.button.option:hover{
  border-color: transparent;
  background-color: #333;
}

.button .loader{
  margin: 0;
  width: var(--font-s);
  height: var(--font-s);
  border-width: 3px;
}

.button .loader + span{
  margin-left: 10px;
}

.button.fluid,
.button.full{
  width: 100%;
  flex: 1;
  box-sizing: border-box;
}

.button.jumbo{
  padding: 12px 18px;
  font-size: var(--font-m);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.button .value-wrapper{
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  white-space: nowrap;
}

.button .loader{
  margin-right: 10px;
}
