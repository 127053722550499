.pagination{
  display: flex;
  align-items: center;
  padding: 10px;
  background: var(--ui-bg-helper);
    border-radius: 5px;
}

.pagination > *{
  flex: 1
}

.pagination .button:first-child{
  margin-left: 0;
}

.pagination .button:last-child{
  margin-right: 0;
}

.pagination .button.left{
  margin-left: auto;
}

.pagination .label{
  flex: 1;
  text-align: center;
}
