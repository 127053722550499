#opening-hours{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#opening-hours .day{
  display: flex;
  flex: 1;
  align-items: center;
  background: var(--ui-element);
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
  padding: 15px;
  gap: 15px;
  min-height: 54px;
}

#opening-hours .day-title{
  display: flex;
  align-items: center;
  gap: 15px;
}

#opening-hours .day .title{
  margin-right: 10px;
}

#opening-hours .day .hours-picker{
  width: 110px;
}

#opening-hours .day .hours-wrap{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}

#opening-hours .day .hours-wrap .hours-label{
  display: flex;
  flex-direction: column;
  user-select: none;
  width: 110px;
  box-sizing: border-box;
  padding: 20px;
  background-color: var(--ui-element);
  border-radius: 5px;
}

#opening-hours .day .hours-wrap .hours-label > span:first-child{
  font-size: var(--font-xxs);
  margin-bottom: 5px;
  color: var(--ui-placeholder)
}

#opening-hours .day .hours-wrap .closed{
  color: var(--invalid-color);
  font-weight: bold;
}

@media only screen and (max-width: 480px) {

  #opening-hours .day{
    flex-direction: column;
  }

  #opening-hours .day .hours-wrap{
    margin: 20px 0;
  }

}
