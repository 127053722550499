#purchase-totals{
  display: flex;
  flex-direction: column;
  gap: 10px
}

#purchase-totals .totals-table {
  flex: 1;
  width: 100%;
}

#purchase-totals .totals-table td{
  padding: 10px 0;
}

#purchase-totals .totals-table td:last-child{
  text-align: right;
  width: auto;
}

#purchase-totals .order-notficiations{
  flex: 1
}

#purchase-totals .purchase-total.vendorPrice td{
  font-size: var(--font-m);
  font-weight: bold;
}

#purchase-totals .purchase-total .tooltip{
  display: inline-flex;
}

#purchase-totals .purchase-total .tooltip .fa{
  cursor: pointer;
  background: var(--ui-element);
  padding: 5px 10px;
  font-size: var(--font-xs);
  border-radius: 5px;
  margin-left: 10px;  
}

#purchase-totals .purchase-total.vendorPrice .price{
  color: var(--main-color)
}

@media only screen and (max-width: 414px) {

  #purchase-totals > *{
    flex-basis: 100%;
    flex: initial !important
  }

}