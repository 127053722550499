.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown .menu-trigger {
  background: var(--ui-element);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 10px 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.dropdown .menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.dropdown .menu-trigger > span{
  white-space: nowrap;
  text-overflow: ellipsis;  
}

.dropdown .menu-trigger .fa {
  color: var(--main-text);
  font-weight: 600;
  font-size: var(--font-s);
  margin: 0;
  vertical-align: baseline;
}

.dropdown .menu {
  background: var(--main-element);
  border-radius: 5px;
  z-index: 9000;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px var(--ui-shadow);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.dropdown .menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown .menu ul {
  list-style: none;
  box-shadow: 0px 8px 8px 0px var(--ui-shadow);  
  padding: 0;
  margin: 0;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown .menu li {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown .menu li:last-child{
  border-bottom: 0;
}

.dropdown .menu li .icon{
  margin-right: 5px;
  width: 20px;
  text-align: center;
  font-size: var(--font-s);
  color: var(--ui-text)
}

.dropdown .menu li > * {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 15px 20px;
  border-bottom: 1px solid var(--ui-border);
}

.dropdown .menu li:last-child > * {
  border-bottom: 0;
}

.dropdown .menu li > *.active-link{
  color: var(--main-color)
}

@media only screen and (max-width: 800px) {

  .dropdown .menu-trigger > span {
    display: none;
  }

  .dropdown .menu-trigger{
    padding: 10px 14px
  }

}
