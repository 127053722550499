.file-uploader{
  text-align: center;
  display: inline-flex;
  background: var(--ui-element)
}

.file-uploader .drop-box {
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  width: 100%;
  padding: 40px;
  align-items: center;
  color: var(--ui-placeholder);
  border: 2px dashed var(--ui-border);
  display: flex;
  transition: transform 0.3s;
  justify-content: center;
}

.file-uploader .drop-box input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.file-uploader .drop-box.draging {
  transform: scale(1.1);
}

.file-uploader .placeholder{
  position: absolute;
  top: 0;
  left: 0;
  background: var(--ui-element-hvr);
  padding: 5px;
  display: flex;
}
