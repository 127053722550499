*{
  font-family: 'Overpass', sans-serif;
}

html,
body{
  height: 100%;
}

#root,
#app{
  min-height: 100%;
  height: 100%;
  position: relative;
}

#app{
  display: flex;
  min-height: 100%;
  height: auto;
}

#app #main{
  flex: 1 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: var(--main-bg);
}

body > tr.vendor {
  display: table !important;
}

.drag-handler{
  cursor: pointer;
  color: var(--ui-placeholder)
}

.relative{
  position: relative;
}

.full-size-loader{
  position: absolute;
  background: rgba(250, 250, 250, 0.75);
  left: 0;
  right: 0;
  display: flex;
  top: 0;
  align-items: center;
  bottom: 0;
  z-index: 10;
  justify-content: center;
}

html{
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  font-family: 'Overpass', sans-serif;
}

body{
  margin: 0;
  padding: 0;
  background: var(--main-bg);
  color: var(--main-text);
  min-height: 100%;
}

input,
textarea{
  border: 1px solid var(--ui-border);
  outline: 0;
  padding: 10px;
  font-size: 14px;
  margin: 0;
  resize: none;
  color: var(--main-text)
}

.row.padding{
  padding: 40px;
}

.row.slider{
  margin: 25px auto;
  margin-left: 40px;
}

.row.wrapped{
  width: 100%;
  display: block;
  margin: 25px auto;
  padding: 0 40px;
  max-width: 1440px;
  box-sizing: border-box;
}

.row .section{
  margin: 20px 0;
}

.price{
  white-space: nowrap;
}

.container{
  max-width: 100%;
}

hr{
  border: 1px solid var(--ui-border);
  color: var(--ui-border);
  background: var(--ui-border);
}

input.disabled,
input:disabled{
  cursor:not-allowed;
  opacity: .7
}

.Toastify .Toastify__toast{
  border-radius: 5px;
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
  line-height: 1.4;
}

#promotion,
#new-promotion .help{
  color: var(--ui-placeholder);
  padding: 0 10px;
  font-size: var(--font-xs);
}


.portal-modal{
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  box-sizing: border-box;
  right: 0;
  justify-content: center;
  bottom: 0;;
  align-items: center;
  background: rgba(30,30,30,.7);
  display: inline-flex;
  padding: 20px;
  animation: fadein .2s;
}

.portal-modal .modal{
  width: 100%;
  max-width: 800px;
}

.portal-modal .inner{
  border-radius: 5px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: scroll;
  width: 100%;
  animation: scaleIn .2s;
  background: var(--ui-bg);
  padding: 20px;
  width: 100%;
  animation: scaleIn .2s;  
}

.portal-modal .filter-select{
  border: 0
}

.portal-modal .modal-header{
  margin-bottom: 20px;
}

.portal-modal .modal-footer{
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.portal-modal .modal-footer > button{
  flex: 1
}

.Toastify__toast-theme--light{
  background: var(--ui-element);
  color: var(--ui-placeholder)
}

@keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}

@keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Firefox < 16 */
@-moz-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Internet Explorer */
@-ms-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}

/* Opera < 12.1 */
@-o-keyframes scaleIn {
    from { transform: scale(0)}
    to   { transform: scale(1)}
}


@media only screen and (max-width: 480px) {

  .row.wrapped{
    padding: 0 20px
  }

  .row.padding{
    padding: 20px;
  }

  .row.slider{
    margin-left: 20px;
  }

}
