.status-dot{
  align-items: center;
  width: 15px;
  line-height: 1;
  text-align: center;
  font-size: var(--font-xxs);
}

.status-dot.pending .fa{
  color: var(--warning-color)
}

.status-dot.active .fa{
  color: var(--valid-color)
}

.status-dot.inactive .fa{
  color: var(--invalid-color)
}
