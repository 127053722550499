#login.container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--main-bg);
}

#login #login-form-wrapper{
  margin: 0 auto 0;
  box-shadow: 0 2px 8px 0 var(--ui-shadow);
  max-width: 480px;
  display: flex;
  flex-direction: column;
  padding: 80px;
  border-radius: 5px;
}

#login #login-form-wrapper .heading{
  margin-bottom: 20px;
}

#login #login-form-wrapper .heading .title{
  margin: 10px 0;
}

#login #login-form-wrapper .logo{
  display: block;
  margin: 0 auto 40px;
  max-width: 360px;
}

#login #login-form-wrapper #login-form{
  width: 100%;
}

@media only screen and (max-width: 600px) {

  #login #login-form-wrapper{
    padding: 40px;
  }

}
