.link.styled > *{
  color: var(--main-color);
  transition: 0.3s;
}

.link.styled:hover > *{
  color: var(--main-text)
}

.link.icon{
  display: flex;
}

.link .icon{
  margin-right: 5px;
  color: var(--main-color);
}

.link.button .icon,
.link.button > *{
  color: inherit;
}