
:root {
  --block: hsl(0 0% 0% / 12%);
  --loader: var(--ui-element);
}

@media (prefers-color-scheme: dark) {
  :root {
    --block: var(--ui-border);
    --loader: var(--ui-element);
  }
}

.skeleton {
  display: flex;
  gap: 15px;
}

.skeleton .skeleton-row{
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: center;
}

.skeleton .skeleton-col{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.skeleton__line{
  background: linear-gradient(-75deg, transparent 40%, var(--loader), transparent 60%) 0 0/200% 100%, var(--block);
  border-radius: 5px;
  animation: load 2s infinite linear;
  background-attachment: fixed;
  height: 20px;
  width: 100%;
}

.skeleton__line.short{
  width: 30%;
}

.skeleton__line.bottom{

}

.skeleton__image {
  background: linear-gradient(-75deg, transparent 40%, var(--loader), transparent 60%) 0 0/200% 100%, var(--block);
  border-radius: 5px;
  animation: load 2s infinite linear;
  background-attachment: fixed;
  height: 90px;
  width: 90px;
}

@keyframes load {
  to {
    background-position: 200% 0;
  }
}