.customer-field .link{
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
  overflow: hidden;
}

.customer-field > div{
  display: flex;
  align-items: center;
}

.customer-field .tags{
  margin-left: 5px;
  display: flex;
}

.customer-field .tags .fa{
  font-size: var(--font-xs);
  margin-right: 5px;
}

.customer-field .tags .fa-gift{
  color: var(--main-color)
}

.customer-field .tags .fa-exclamation-triangle{
  color: var(--invalid-color)
}

.customer-field .tags .fa-whatsapp{
  color: var(--whatsapp)
}
