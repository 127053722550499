#help .items{
    gap: 15px;
    display: flex;
    flex-direction: column;
}
  
#help .items .question{
    padding-bottom: 10px;
    font-weight: bold;
    font-size: var(--font-m);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

#help .items .question .fas{
    font-size: var(--font-xxl);
    color: var(--main-color);
    margin-right: 20px;
}

#help .items .answer{
    line-height: 1.2;
}

#help .items .answer a{
    color: var(--main-color);
}

#help .items .answer p{
    margin: 10px 0;
}

#help .items .answer ul{
    margin-left: 20px;
    padding-left: 20px;
    list-style: disc;
}

#help .items .answer ul li{
    margin: 5px 0;
}
  