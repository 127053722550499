.steps{
  display: flex;
  margin: 40px 0 60px
}

.steps .step{
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

.steps .step:last-child{
  flex: none
}

.steps .step .number{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--ui-border);
  background: var(--ui-border);
  color: var(--ui-placeholder);
  border-radius: 30px;
}

.steps .step .label{
  position: absolute;
  color: var(--ui-placeholder);
  left: 0;
  transform: translateX(calc(-50% + 15px));
  font-size: var(--font-xs);
  white-space: nowrap;
  margin-top: 10px;
}

.steps .step .separator{
  height: 3px;
  background: var(--ui-border);
  flex: 1;
  margin: 0 10px
}


.steps .step.active .number{
  border: 3px solid var(--main-color);
  background: var(--main-color);
  color: white;
}

.steps .step.active .label{
  color: var(--main-color)
}

.steps .step.active .separator{
  background: var(--main-color);
}

.steps .step.done .number{
  border: 3px solid var(--valid-color);
  background: var(--valid-color);
  color: white;
}

.steps .step.done .label{
  color: var(--valid-color)
}

.steps .step.done .separator{
  background: var(--valid-color);
}
