.option-list{
  padding: 10px 0;
}

.option-list .options{
  margin-bottom: 20px;
  padding: 15px;
}

.option-list .options:last-child{
  margin-bottom: 0;
}

.option-list .option{
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--ui-border);
  margin: 0 0 10px;
  padding: 0 10px 10px;
}


.option-list .options{
  background: var(--ui-bg);
}

.option-list .options .title{
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.option-list .option .actions{
  margin-left: auto;
}

.option-list .option .actions .button{
  padding: 5px;
  color: white;
  font-size: var(--font-xxs);
  width: 80px;
}

.option-list .selected-options .option .actions .button{
  background-color: var(--invalid-color);
  border-color: transparent;
}

.option-list .available-options .option .actions .button{
  background-color: var(--valid-color);
  border-color: transparent;
}

.option-list .selected-options .option .actions .button:hover{
  background-color: white;
  color: var(--invalid-color);
}

.option-list .available-options .option .actions .button:hover{
  background-color: white;
  color: var(--valid-color);
}
