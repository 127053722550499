#footer{
    margin-top: auto;
    border-top: 1px solid var(--ui-border);
    padding: 20px;
    display: flex;
}

#footer .links{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: auto;
}