.sidebar-view{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.sidebar-view .sidebar-menu{
  background: var(--ui-bg);
  border-radius: 5px;
  overflow: hidden;
  min-width: 240px
}

.sidebar-view .sidebar-content{
  background: var(--ui-bg);
  border-radius: 5px;
  padding: 20px;
  min-width: 320px;
  box-sizing: border-box;
}

@media only screen and (max-width: 350px) {

  .sidebar-view .sidebar-content{
    min-width: 0;
  }

}