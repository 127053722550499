.title h1,
.title h2,
.title h3,
.title h4{
  position: relative;
  font-weight: bold;  
}

.title h1{
  font-size: var(--font-xxl);
}

.title h2{
  font-size: var(--font-xl);
  color: var(--main-text)
}

.title h4{
  font-size: var(--font-m)  
}