.heading{
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  user-select: none;
  margin-bottom: 15px;
}

.heading.left{
  text-align: left;
}

.heading.spacing{
  margin: 40px 0;
}

.heading .title{
  font-size: var(--font-l);
  position: relative;
  font-weight: bold;
}

.heading .info{
  font-size: var(--font-s);
  color: var(--ui-placeholder);
  margin: 5px 0;
  line-height: 1.2;
}
