.package-box{
    background: var(--ui-element);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 40px;
}

.package-box .package-box-inner{
    display: flex;
    align-items: center;
}

.package-box .title{
    font-size: var(--font-l);
    position: relative;
    font-weight: bold;
    margin-bottom: 1rem;
}

.package-box .icon{
    color: var(--main-color);
    font-size: 48px;
    margin-right: 20px;
    width: 60px;
    text-align: center;    
}

.package-box .price{
    font-size: var(--font-m);
    font-weight: bold;    
}

.package-box .button{
    margin-left: 1rem;
}

.package-box p{
    line-height: 1.5;  
    color: var(--ui-placeholder)  
}

.package-box ul{
    color: var(--ui-placeholder);
    list-style: circle;
    padding-left: 40px;
    line-height: 1.5;  
}