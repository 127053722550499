#orders-list .actions{
  display: flex;
}

#orders-list .actions .button{
  position: relative;
}

#orders-list .orders-list-item .assigned-rider{
  justify-content: center;
}

#orders-list .orders-list-item td.id{
  vertical-align: c

}

#orders-list .orders-list-item td.id .fa{
  color: var(--invalid-color);
  margin-right: 5px;
}

#orders-list .orders-list-item .order-tags .fa{
  color: var(--main-color)
}

/****************************/
/********** Fields **********/
/****************************/

/********** Cooking time field **********/

.cooking-time-field .cooking-time{
  display: flex;
  justify-content: center;
}

/********** Purchase status field **********/

.purchase-status-field{
  padding: 0;
  position: relative;
  width: 10px;
}

.purchase-status-field .placeholder{
  width: 10px;
  top: 0;
  bottom: 0;
  position: absolute;
}

.purchase-status-field .placeholder .placeholder{
  height: 100%;
}

.purchase-status-field.inqueue .placeholder{
  background: white
}

.purchase-status-field.processed .placeholder{
  background: grey
}

.purchase-status-field.inpreparation .placeholder{
  background: #ffdc41
}

.purchase-status-field.readyforcollection .placeholder{
  background: #ff8b12
}

.purchase-status-field.ontheway .placeholder{
  background: #1984ff
}

.purchase-status-field.atlocation .placeholder{
  background: #32d13b
}

.purchase-status-field.completed .placeholder{
  background: #3c5922
}

.purchase-status-field.paymentfailed .placeholder,
.purchase-status-field.unprocessed .placeholder,
.purchase-status-field.rejected .placeholder,
.purchase-status-field.deliveryfailed .placeholder,
.purchase-status-field.cancelled .placeholder{
  background: var(--invalid-color)
}

@media screen and (max-width: 1200px) {

  td.purchase-status-field{

    padding: 0;
    position: relative;
    width: 100%;
  }

  td.purchase-status-field::before{
    display: none;
  }

  .purchase-status-field .placeholder{
    padding: 0;
    width: 100%;
    position: relative;
  }

}


/********************************/
/********** Fields end **********/
/********************************/
