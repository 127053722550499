.loader,
.loader:after{
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.loader.small,
.loader.small:after{
  width: 10px;
  height: 10px;  
}

.loader{
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  border-top: 5px solid var(--ui-placeholder);
  border-right: 5px solid var(--ui-placeholder);
  border-bottom: 5px solid var(--ui-placeholder);
  border-left: 5px solid var(--ui-text);
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;
}

.loader.small{
  border-top-width: 3px;
  border-right-width: 3px;
  border-bottom-width: 3px;
  border-left-width: 3px;
}

.loader.main{
  border-left-color: var(--main-color);
  border-top-color: rgba(255, 42, 34, 0.2);
  border-right-color: rgba(255, 42, 34, 0.2);
  border-bottom-color: rgba(255, 42, 34, 0.2);
}

.loader.white{
  border-left-color: white;
  border-top-color: rgba(255, 255, 255, 0.4);
  border-right-color: rgba(255, 255, 255, 0.4);
  border-bottom-color: rgba(255, 255, 255, 0.4);
}

.loader-overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ui-overlay);
  z-index: 1000;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
