.modal{
  display: flex;
  flex-direction: row;
  height: 100%;
  max-height: 80vh;
  border-radius: 5px;
  overflow: hidden;
  z-index: 9000;
  box-shadow: 0px 8px 8px 0px var(--ui-shadow);  
}

.modal .modal-info{
  width: 90vw;
  max-width: 720px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--ui-bg)
}

.modal.full .modal-info{
  max-width: none;
}

.modal .modal-header{
  display: flex;
  flex-direction: column;
}

.modal .modal-header .title{
  font-size: var(--font-l);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.modal .modal-content{
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 5px;
}

.modal .modal-footer{
  margin-top: auto;
  display: flex;
  min-height: 40px;
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {

  .modal{
    flex-direction: column;
  }

  .modal .modal-info{
    width: 90vw
  }

}
