.context-menu{
  position: relative;
}

.context-menu > .button{
  font-size: var(--font-s);
  width: 100%;
  box-shadow: 0px 2px 2px 0px var(--ui-shadow);
}

.context-menu > .button .fa{
  margin: 0;
}

.context-menu > .button .placeholder{
  margin-left: 10px;
}

.context-menu-dropdown{
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  transform: translate(calc(-100% - 5px), 20px);
  background: var(--ui-element);
  border-radius: 5px;
  z-index: 10;
  text-align: left;
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,.4);
  min-width: 100%;
  border: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.context-menu-dropdown.opened{
  opacity: 1;
  transform: translate(calc(-100% - 5px), 0);
  visibility: visible;
}

.context-menu-dropdown li a,
.context-menu-dropdown li .nav-button{
  white-space: nowrap;
  padding: 8px 15px;
  box-sizing: border-box;
  line-height: 1.5;
  width: 100%;
  display: flex;
  font-size: var(--font-s);
  cursor: pointer;
}

.context-menu-dropdown li .link > *{
  color: var(--ui-text);
}

.context-menu-dropdown li{
  border-bottom: 1px solid var(--ui-border);
}

.context-menu-dropdown li a{
  display: flex;
}

.context-menu-dropdown li:first-child{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px
}

.context-menu-dropdown li:last-child{
  border-bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.context-menu-dropdown li .fab,
.context-menu-dropdown li .fas,
.context-menu-dropdown li .fa{
  margin-right: 5px;
}

.context-menu-dropdown li{
  background: var(--ui-element)
}

.context-menu-dropdown li:hover{
  background: var(--ui-element-hvr)
}

.context-menu-dropdown li.valid{
  background: var(--valid-color);
  color: white
}

.context-menu-dropdown li.invalid{
  background: var(--invalid-color);
  color: white
}

.context-menu-dropdown li.warning{
  background: var(--warning-color);
  color: white
}

/* SMALL */

.context-menu.small > .button{
  font-size: var(--font-xs)
}

.context-menu.small .context-menu-dropdown{
  /* transform: translate(-35px, 0%); */
}

.context-menu.small .context-menu-dropdown li a,
.context-menu.small .context-menu-dropdown li .nav-button{
  padding: 8px 12px;
  font-size: var(--font-xs);
}

@media only screen and (max-width: 480px) {

  .context-menu .context-menu-dropdown{
    transform: translate(0, 150%);
    bottom: 0;
    top: auto;
  }

  .context-menu .context-menu-dropdown.opened{
    transform: translate(0, calc(100% + 5px));
  }

}
