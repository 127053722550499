.chard-cards{
    display: flex;
    gap: 20px;
    overflow-x: auto;
}

.chard-cards::-webkit-scrollbar {
    display: none;
}

.chard-cards .card{
    padding: 20px;
    background-color: var(--ui-element);
    border-radius: 5px;
}

.chard-cards .card .label{
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
}

.chard-cards .card .value{
    font-size: var(--font-xl);
    color: var(--main-color)
}